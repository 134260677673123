import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import * as Icon from 'react-feather';
import { Link } from "gatsby"; 




const BlogDetails = () => (
    <Layout>
        <SEO title="Conditions de vente" /> 

        <Navbar />

        <PageBanner pageTitle="Conditions de vente" /> 

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> Dernière mise à jour le 20 février 2023
                                        </li>
                                    </ul>
                                </div>

                                <h2>Droit de rétractation</h2> 

                                <p>Conformément aux dispositions du code de la consommation, l’acheteur dispose d’un délai de 14 jours à compter de la date de livraison de sa commande, pour se rétracter si le service ne lui convient pas et demander l’échange ou le remboursement sans pénalité.

Le droit de rétractation peut être exercé en ligne par mail à l’adresse suivante : contact@fenuahosting.com. Dans ce cas, un accusé de réception sur un support durable sera immédiatement communiqué à l’acheteur. Tout autre mode de déclaration de rétractation est accepté. Il doit être dénué d’ambiguïté et exprimer la volonté de se rétracter.

En cas d’exercice du droit de rétractation dans le délai susvisé, sont remboursés le prix du ou des service(s) acheté(s)

L’échange (sous réserve de disponibilité) ou le remboursement sera effectué dans un délai de 7 jours ouvrés , et au plus tard, dans le délai de 14 jours à compter de la réception de la demande.</p>
                                
                                <h2>Conditions d'utilisation</h2>
                                <p>Les données, qu’elles soient accessibles ou non, doivent répondre aux lois françaises en vigueur ainsi qu’à celles du pays d’origine du client. il est strictement interdit dhéberger :</p>
                                <ul>
                                    <li>Des fichiers dont le client n’a pas l’accord nécessaire à la diffusion.</li>
                                    <li>Des fichiers à caractère injurieux, diffamatoires, racistes, xénophobes, incitant à la haine et/ou la discrimination. Il n’est pas possible d’héberger des fichiers à caractère pornographique. L’envoi de courriers non sollicités (SPAM) via les services fenuahosting.com est strictement interdit et sanctionné. Cela pourra entraîner la fermeture immédiate et sans préavis du compte concerné.</li>
                                </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </Layout>
)

export default BlogDetails;